import React, { useEffect, useRef, useState } from 'react';
import videoFile from '../video.mp4';
import { useLocation } from 'react-router-dom';
import Loading from '../Loading';
import { AboutContainer, Section, Title, Content } from './About';
import styled from 'styled-components';
import { FaPlay, FaPause, FaRedo } from 'react-icons/fa';

function AboutUs() {
  const location = useLocation();
  const videoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false); // State for muted video
  const [isEnded, setIsEnded] = useState(false); // State for when the video ends
  const [showControls, setShowControls] = useState(false); // To show/hide play/pause/repeat icons on hover
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // To detect small screens
  const [showPlayIcon, setShowPlayIcon] = useState(true); // Show play icon initially on mobile

  // Adjust screen size check on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handlePlayPause = () => {
    const videoElement = videoRef.current;
    if (isPlaying) {
      videoElement.pause();
      setShowPlayIcon(true); // Show play icon after pausing on mobile
    } else {
      videoElement.play();
      setShowPlayIcon(false); // Hide play icon when playing on mobile
    }
    setIsPlaying(!isPlaying);
  };

  const handleVideoEnd = () => {
    setIsEnded(true);
    setIsPlaying(false);
    setShowPlayIcon(true); // Show play icon when video ends on mobile
  };

  const handleRepeat = () => {
    const videoElement = videoRef.current;
    videoElement.currentTime = 0; // Reset the video to the beginning
    videoElement.play();          // Play the video
    setIsPlaying(true);
    setIsEnded(false);            // Hide repeat icon once it starts playing again
    setShowPlayIcon(false);       // Hide play icon after restarting on mobile
  };

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleCanPlay = () => {
      setIsLoading(false);
    };

    const handleError = () => {
      setIsLoading(false);
    };

    videoElement.addEventListener('canplay', handleCanPlay);
    videoElement.addEventListener('error', handleError);

    // Set initial video volume
    videoElement.volume = 0.1;

    return () => {
      videoElement.removeEventListener('canplay', handleCanPlay);
      videoElement.removeEventListener('error', handleError);
    };
  }, []);

  useEffect(() => {
    if (location.hash) {
      const elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const handleMouseEnter = () => {
    if (!isMobile) setShowControls(true); // Only show on hover for non-mobile screens
  };

  const handleMouseLeave = () => {
    if (!isMobile) setShowControls(false); // Only hide on hover for non-mobile screens
  };

  return (
    <AboutContainer>
      {isLoading && <Loading />}
      <Section>
        <Title>О нас</Title>
        <Content>
          Чайный магазин "Tea House TLV" - не просто место для покупки чая онлайн. Это настоящее чайное пространство, где каждый может найти для себя что-то особенное. Наша история в Израиле началась в 2020 году, и на сегодняшний день мы стали любимым местом для многих ценителей китайского чая. Ассортимент, всегда представленный в наличии, включает все популярные категории китайских чаев, от шу и шен пуэров до светлых и темных улунов. Также у нас имеется широкий выбор чайной посуды и инструментов для чайной церемонии.
          <br />
          В чайном пространстве "Tea House TLV" вы не только сможете получить свой заказ лично и, при желании, выбрать чай или посуду, но и расслабиться, выпив чая со своими друзьями в приятной и непринужденной обстановке. Также мы предлагаем выездные чайные церемонии. У нас чай становится не просто напитком, а источником радости и вдохновения.
          <br />
          Чайное пространство находится по адресу: Menora 28, Tel Aviv. 
          Сообщайте пожалуйста заранее, когда планируете нас посетить.
        </Content>
        <VideoWrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Video
            ref={videoRef}
            muted={isMuted} // Automatically handle the muted state
            playsInline
            src={videoFile}
            type="video/mp4"
            onClick={handlePlayPause} // Add play/pause functionality on video click
            onEnded={handleVideoEnd}   // Trigger when the video ends
          >
            Your browser is not supported!
          </Video>

          {/* Mobile Screen: Show play/pause/repeat icons conditionally */}
          {isMobile && showPlayIcon && (
            <PlayPauseButton onClick={isEnded ? handleRepeat : handlePlayPause}>
              {isEnded ? <FaRedo /> : <FaPlay />}
            </PlayPauseButton>
          )}

          {/* Regular Screen: Show controls only on hover */}
          {!isMobile && showControls && (
            <PlayPauseButton onClick={isEnded ? handleRepeat : handlePlayPause}>
              {isEnded ? <FaRedo /> : isPlaying ? <FaPause /> : <FaPlay />}
            </PlayPauseButton>
          )}
        </VideoWrapper>
      </Section>
    </AboutContainer>
  );
}

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%; 
  border-radius: 8px;
  overflow: hidden;
`;

const Video = styled.video`
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  cursor: pointer;
  
  @media (max-width: 768px) {
    height: 230px;
  }
`;

const PlayPauseButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  @media (max-width: 768px) {
    font-size: 24px;
    padding: 8px;
  }
`;

export default AboutUs;







