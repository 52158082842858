import React from 'react'
import styled from 'styled-components';
function About() {
  return (
    <AboutContainer>
        <Section>
        <Title></Title>
        <Content></Content>
        </Section>
       
    </AboutContainer>
  )
}
export const AboutContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px;
  padding-top: 130px;
//   min-height: 80vh;
`;

export const Section = styled.section`
  margin: 20px 0;
  width: 80%;
  max-width: 800px;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);

  @media(max-width: 768px){
    max-width: 500px;
  }
`;

export const Title = styled.h2`
  color: #333;
  font-size: 24px;
`;

export const Content = styled.p`
  color: #666;
  font-size: 16px;
  background-image: url(${props => props.background});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
export default About;