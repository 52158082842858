import React, { useEffect, useState } from 'react';
import axios from 'axios';
import baseUrl from '../../baseURL';
import Creatable from 'react-select/creatable';
import { useCart } from '../Cart/CartContext';
import styled from 'styled-components';
import { FaArrowDown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading'; 
import { FaPlus } from 'react-icons/fa';
import { components } from 'react-select';
import { Helmet } from 'react-helmet';

const CustomDropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <FaPlus /> 
    </components.DropdownIndicator>
  );
};

// Helper function to shuffle an array
const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

function AllProducts() {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setData] = useState([]);
  const [changedPrices, setChangedPrices] = useState([]);
  const { addToCart } = useCart();
  const navigate = useNavigate();
  console.log(data);

  function isMobileDevice() {
    return (
      (typeof window.orientation !== "undefined") ||
      (navigator.userAgent.indexOf('IEMobile') !== -1)
    );
  }

  const [isMobile, setIsMobile] = useState(isMobileDevice());

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(isMobileDevice());
    });

    return () => {
      window.removeEventListener('resize', () => {
        setIsMobile(isMobileDevice());
      });
    };
  }, []);

  const fetchProducts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/tea-house/products`);
      let fetchedData = response.data?.products || [];

      // Shuffle the fetched data for random allocation
      fetchedData = shuffleArray(fetchedData);

      // Set the shuffled data first
      setData(fetchedData); 

      // Display the first 10 products
      setProducts(fetchedData.slice(0, 10)); 
      
      // Initialize prices for the first 10 products
      setChangedPrices(fetchedData.slice(0, 10).map(product => product.price)); 

      // Set current index to 10 after initial display
      setCurrentIndex(10); 
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const pagination = () => {
    if (currentIndex < data.length) { // Check if there are more products to load
      const nextIndex = currentIndex + 10;
      const newProducts = data.slice(currentIndex, nextIndex); // Get the next set of products
      setProducts(prevProducts => [...prevProducts, ...newProducts]); // Append new products
      setChangedPrices(prevPrices => [
        ...prevPrices,
        ...newProducts.map(product => product.price)
      ]);
      setCurrentIndex(nextIndex); // Update the index
    }
  };

  // Initial fetch on mount
  useEffect(() => {
    fetchProducts();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      top: '-70px',
      left: '-10px',
      width: '200px',
      textAlign: 'center',
      cursor: 'pointer',
      zIndex: '1000'
    }),
    control: (provided) => ({
      ...provided,
      minHeight: '5px',
      height: '27px',
      margin: 'auto',
      marginTop: '10px',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      alignSelf: 'center',
      padding: '0 8px',
    }),
    input: (provided) => ({
      ...provided,
      outline: 'none',
      border: 'none',
      cursor: 'none',
      opacity: '0'
    }),
    placeholder: (provided) => ({
      ...provided,
      cursor: 'none',
      opacity: '0'
    })
  };

  const ChangePrice = (selectedOption, index) => {
    if (selectedOption) {
      let result = (selectedOption.value / products[index].amount) * products[index].price;
      if (selectedOption.value === 100 || selectedOption.value === 150) {
        result *= 0.85;
      } else if (selectedOption.value === 200) {
        result *= 0.8;
      }
      const updatedPrices = [...changedPrices];
      updatedPrices[index] = result.toFixed(0);
      setChangedPrices(updatedPrices);
    }
  };

  const navigateToProduct = (product) => {
    navigate(`/tea-house/products/${product?.id}`, { state: { product } });
  };

  return (
    <AllProductsDiv>
      <Helmet>
        {/* Helmet content */}
      </Helmet>
      {products.length > 0 && products.map((product, currentIndex) => (
        product && ( // Ensure product exists
          <EveryProductDiv key={product?.id}>
            <ImgTitleDiv>
              <Helmet>
                {/* Helmet for SEO */}
              </Helmet>
              <img src={product?.imageURL} alt={product?.title} onClick={() => navigateToProduct(product)} />
              <h3 style={{ marginTop: '12px' }}>{product?.title}</h3>
            </ImgTitleDiv>

            <AmountPriceDiv>
              <AmountDiv>
                <Creatable 
                  isSearchable={!isMobile} 
                  menuPosition="absolute"
                  styles={customStyles}
                  placeholder='amount' 
                  defaultValue={{ value: product?.amount, label: product?.amount }}
                  options={[
                    { value: product?.amount, label: product?.amount },
                    { value: (Number(product?.amount) * 2), label: Number(product?.amount) * 2 },
                    { value: Number(product.amount) === 1 ? Number(product.amount) * 3 : (Number(product?.amount) * 4), label: Number(product.amount) === 1 ? Number(product.amount) * 3 : Number(product?.amount) * 4 },
                    { value:  Number(product.amount) === 1 ? Number(product.amount) * 4 : (Number(product?.amount) * 6), label: Number(product.amount) === 1 ? Number(product.amount) * 4 : Number(product?.amount) * 6 },
                    { value:  Number(product.amount) === 1 ? Number(product.amount) * 5 : (Number(product?.amount) * 8), label:  Number(product.amount) === 1 ? Number(product.amount) * 5 :  Number(product?.amount) * 8 }
                  ]}
                  onChange={(selectedOption) => ChangePrice(selectedOption, currentIndex)}
                  components={{ DropdownIndicator: CustomDropdownIndicator }}
                />
              </AmountDiv>
              <PriceDiv className="price">Цена: <strong>&#8362; {changedPrices[currentIndex]}</strong></PriceDiv>
            </AmountPriceDiv>
            <ButtonDiv>
              <button onClick={() => addToCart(product, changedPrices[currentIndex])}>Добавить</button>
            </ButtonDiv>
          </EveryProductDiv>
        )
      ))}
      {currentIndex < data.length && (
        <ButtonArrowDown onClick={pagination}>
          <FaArrowDown />
        </ButtonArrowDown>
      )}
    </AllProductsDiv>
  );
}

export default AllProducts; 
  
  
const AllProductsDiv = styled.div`
    padding: 190px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    max-width: 60%; 
    margin: 0 auto; 
    @media(max-width: 768px){
      padding: 110px 15px;
    }
`;

const EveryProductDiv = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid green;
  padding: 10px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ImgTitleDiv = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media(max-width: 768px){
    flex-direction: column;
  }
  img {
    width: 100px;
    height: 100px;
    margin-right: 25px;
    cursor: pointer;

    @media (max-width: 768px) {
      width: 70px;
      height: 70px;
      margin: auto;
      margin-top: 5px;
    }
  }

  h3 {
    font-size: 17px;

    @media (max-width: 768px) {
      font-size: 12px;
      margin-top: -2px;
    }
  }
`;
const AmountPriceDiv = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center; // Space out the amount and price
  flex-direction: column;
  
  @media (max-width: 768px) {
    align-items: center;
  }
`;

const AmountDiv = styled.div`
  display: flex;
  align-items: center;
`;

const PriceDiv = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-size: 15px;
  color: green;
  white-space: nowrap; // Ensure no wrapping
  margin-top: 10px;
  @media (max-width: 768px) {
    margin: auto;
    margin-top: 10px;
    font-size: 12px;
  }
`;

const ButtonDiv = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  button {
    width: 180px;
    height: 40px;
    background-color: #2e4f24;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;

    @media (max-width: 768px) {
      width: 120px;
      height: 30px;
      font-size: 12px;
    }
  }
`;
const ButtonArrowDown = styled.button`
  background-color: #eae3d1; // Neutral color for the button background
  color: #4CAF50; // Green color for the icon
  border: none;
  cursor: pointer;
  width: 50px; // Fixed width for a circular shape
  height: 50px; // Fixed height for a circular shape
  border-radius: 50%; // Fully rounded borders to create a circle
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); // Subtle shadow for 3D effect
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s; // Smooth transitions for interactive effects
  margin: auto;
  &:hover, &:focus {
    background-color: #4CAF50; // Change background color on hover/focus
    color: #fff; // Change icon color on hover/focus
    box-shadow: 0 6px 12px rgba(0,0,0,0.2); // Enhance shadow on hover/focus
    transform: translateY(-2px); // Slight raise effect on hover/focus
  }

  &:active {
    transform: translateY(1px); // Slight press-down effect on click
  }
`;



      
   

    






