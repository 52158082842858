import React from 'react'
import { AboutContainer, Section, Title, Content } from './About'

function AboutDelivery() {
  return (
    <AboutContainer>
         <Section>
        <Title>Оплата и Доставка</Title>
        <Content id='oplata'>
        После оформления заказа на нашем сайте мы свяжемся с вами и вы выберете максимально удобный для вас способ оплаты и доставки.
        <br></br>
        Оплата<br></br>
        1. Онлайн-оплата: Мы принимаем оплату через платёжное приложение BIT.<br></br> 
        2. Оплата при получении: Вы можете оплатить заказ наличными при получении товара непосредственно в чайном пространстве. В некоторых случаях данный способ оплаты доступен при курьерской доставке.<br></br>
        3. Банковский перевод: При выборе этого способа оплаты, вы получите наши реквизиты для проведения банковского перевода. Пожалуйста, учитывайте время совершения транзакции при расчете сроков доставки.<br></br>

        Доставка<br></br>
        4. Курьерская доставка: Наши курьеры оперативно доставят заказ прямо до двери вашей квартиры или офиса. В некоторых случаях это может быть сотрудник курьерской службы.<br></br>
        5. Самовывоз: Вы всегда можете забрать свой заказ самостоятельно из нашего чайного пространства "Tea House TLV". Мы будем рады видеть вас и при необходимости, помочь с выбором чая или посуды на месте.<br></br>
        6. Выездная чайная церемония: Хотите устроить особый чайный праздник? Мы предлагаем услуги выездной чайной церемонии на ваше мероприятие или торжество. Свяжитесь с нами для уточнения деталей.<br></br>
        7. Доставка почтой: Для клиентов в отдалённых регионах и других странах осуществляется доставка почтой. Сроки получения заказа зависят от вашего местоположения.<br></br>

        Мы ценим ваше время и комфорт, поэтому стараемся предоставить максимально гибкие и удобные варианты оплаты и доставки. Если у вас есть какие-либо вопросы или специальные пожелания, свяжитесь с нами любым удобным для вас способом. Мы всегда готовы помочь!<br></br>
        </Content>
      </Section>
    </AboutContainer>
  )
}

export default AboutDelivery