import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
function OneEvent() {
    const location = useLocation();
    const event = location.state.event;
    const poster = event?.videoImageURL;
    const descriptOrdered = (description) => {
        return description.split('/n').map((line, index) => (
            <span key={index}>
                {line[line.length - 2] !== '-' ? <>{line}<br />&nbsp;&nbsp;</> : <>&nbsp;&nbsp;{line}<br/></>}
            </span>
        ));
    };
    return (
        <>
        <Helmet>
                    <meta name="description" content="Выбор чая Выбор китайского чая, китайский набор, столик для чая, столик для китайского чая, чайная продукция, китайская утварь, церемония, китайская чайная, китайская чайная в Израиле, китайская чайная тель-авив, попить чай, попить китайский чай, заказать мероприятие, мероприятие, чайная церемония, китайское место, чай, китайский, набор посуды, набор китайской посуды, Раздел Чая, Чай, Китайский чай, Китайская продукция, Тель-Авив, Израиль" />
                    <meta name="keywords" content="Выбор чая Выбор китайского чая, китайский набор, столик для чая, столик для китайского чая, чайная продукция, китайская утварь, церемония, китайская чайная, китайская чайная в Израиле, китайская чайная тель-авив, попить чай, попить китайский чай, заказать мероприятие, мероприятие, чайная церемония, китайское место, чай, китайский, набор посуды, набор китайской посуды, Раздел Чая, Чай, Китайский чай, Китайская продукция, Тель-Авив, Израиль" />
            </Helmet>
            <EventContainer>
            {event?.videoURL ? (
                <EventVideo controls playsInline poster={poster}>
                    <source src={event.videoURL} type="video/mp4"/>

                    Your browser does not support the video tag.
                </EventVideo>
                
            ) : (
                <EventImage src={event.imageURL} alt={event.title} />
            )}
            <EventContent>
            <Helmet>
                    <meta name="description" content={`${event?.description}`} />
                    <meta name="keywords" content={`${event?.title}`} />
            </Helmet>
                <EventTitle>{event.title}</EventTitle>
                <EventDescription>{descriptOrdered(event.description)}</EventDescription>
            </EventContent>
            
        </EventContainer>
        </>
        
    );
}

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const EventContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 20px;
  max-width: 70%;
  margin: auto;
  background: linear-gradient(to bottom, #fff, #eae3d1); /* Linear gradient background */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  animation: ${fadeInUp} 1s ease-out;
  padding-top: 180px;
  cursor: pointer;

  @media (max-width: 768px) {
    max-width: 90%;
    padding-top: 130px;
  }
`;


const EventImage = styled.img`
    width: 100%;
    max-width: 600px;
    border-radius: 8px; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 400px;
    transition: transform 0.3s ease-in-out;
    object-fit: cover;
    &:hover {
        transform: scale(1.15);
    }
    @media(max-width: 768px){
        max-width: 400px;
    }
`;

const EventContent = styled.div`
    text-align: center;
`;
const EventVideo = styled.video`
    width: 100%;
    max-width: 500px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    height: 400px;
    object-fit: cover;

    &:hover {
        transform: scale(1.03);
    }
    @media (max-width: 768px) {
        max-width: 225px;
        height: 200px;
    }
    animation: ${fadeInUp} 1s ease-out; /* Apply the same animation to the video */
`;
const EventTitle = styled.h1`
    font-size: 1.7rem;
    color: #2e4f24;
    animation: ${fadeInUp} 1s ease-out;
    @media (max-width: 768px) {
        font-size: 1.2rem;
    }
`;

const EventDescription = styled.p`
    flex-grow: 1;
  color: green;
  font-size: .9rem;
  text-align: justify;
  padding-right: 25px;
  padding-left: 25px;
  box-sizing: border-box;
  @media (max-width: 768px){
    font-size: 4.2vw;
    text-align: left; 
    text-indent: 10px;
    padding-right: 5px;
    padding-left: 5px;
  }
`;

export default OneEvent;






