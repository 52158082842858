import React from 'react'
import {AboutContainer, Section, Title, Content} from './About'
function AboutBonuses() {
  return (
    <AboutContainer>
        <Section>
            <Title>Накопительные бонусы и скидки<br></br></Title>
            <Content>
            Мы ценим каждого нашего клиента и стремимся сделать ваше чаепитие еще более приятным. Поэтому предлагаем специальные программы накопительных бонусов и различные скидки для постоянных клиентов.<br></br>
            Программа накопительных бонусов:<br></br>
            Каждая ваша покупка в "Tea House TLV" приносит вам бонусные баллы. С накоплением баллов вы можете получить дополнительные скидки на свои следующие покупки или обменять баллы на подарки и бонусные предложения.<br></br>
            Скидки для постоянных клиентов:<br></br>
            Мы ценим ваше доверие и предлагаем специальные скидки для наших постоянных клиентов. При регулярных покупках у нас вы получаете доступ к эксклюзивным предложениям и скидкам на лучшие сорта чая, посуду и аксессуары.<br></br>
            Акции и специальные предложения:<br></br>
            Следите за нашими акциями и специальными предложениями, чтобы получить еще больше выгоды от своих покупок. Мы регулярно проводим акции с дополнительными скидками, подарками и бонусами для наших клиентов.<br></br>

            Присоединяйтесь к нашим программам и получайте больше радости от чаепития в "Tea House TLV"!
            </Content>
        </Section>
    </AboutContainer>
  )
}

export default AboutBonuses