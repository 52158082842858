import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import Creatable from 'react-select/creatable';
import { useCart } from '../Cart/CartContext';
import { components } from 'react-select';
import { FaPlus } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const CustomDropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <FaPlus />
    </components.DropdownIndicator>
  );
};

function OneProductItem() {
  const location = useLocation();
  const state = location.state;
  const product = state ? state.product : null;
  const [changedPrice, setChangedPrice] = useState(product ? product.price : 0);
  const { addToCart } = useCart();
  
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function isMobileDevice() {
      return (typeof window.orientation !== "undefined") || navigator.userAgent.indexOf('IEMobile') !== -1;
    }
    setIsMobile(isMobileDevice());
    
    window.addEventListener('resize', () => {
      setIsMobile(isMobileDevice());
    });

    return () => {
      window.removeEventListener('resize', () => {
        setIsMobile(isMobileDevice());
      });
    };
  }, []);

  const options = product ? [
    { value: product?.amount, label: product?.amount },
    { value: (Number(product?.amount) * 2), label: Number(product?.amount) * 2 },
    { value: Number(product.amount) === 1 ? Number(product.amount) * 3 : (Number(product?.amount) * 4), label: Number(product.amount) === 1 ? Number(product.amount) * 3 : Number(product?.amount) * 4 },
    { value:  Number(product.amount) === 1 ? Number(product.amount) * 4 : (Number(product?.amount) * 6), label: Number(product.amount) === 1 ? Number(product.amount) * 4 : Number(product?.amount) * 6 },
    { value:  Number(product.amount) === 1 ? Number(product.amount) * 5 : (Number(product?.amount) * 8), label:  Number(product.amount) === 1 ? Number(product.amount) * 5 :  Number(product?.amount) * 8 }
  ] : [];

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      top: '-70px',
      left: '-10px',
      width: '200px',
      textAlign: 'center',
      zIndex: '1000'

    }),
    control: (provided) => ({
      ...provided,
      width: '100px',
      minHeight: 'auto',
      height: 'auto',
      margin: 'auto auto',
      marginTop: '14px',
      display: 'flex',
      alignItems: 'center',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '6px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    input: (provided) => ({
      ...provided,
      outline: 'none', /* Remove the default outline */
      border: 'none',
      cursor: 'none',
      opacity: '0' /* Remove the default border */
    }),
    placeholder: (provided) => ({
      ...provided,
      cursor: 'none',
      // opacity: '0' /* Remove the default outline */
    })
  };
//   const ChangePrice = (selectedOption, index) => {
//     if (selectedOption) {
//       let result = (selectedOption.value / products[index].amount) * products[index].price;
//       if (selectedOption.value === 100 || selectedOption.value === 150) {
//         result *= 0.85;
//       } else if (selectedOption.value === 200) {
//         result *= 0.8;
//       }
//       const updatedPrices = [...changedPrices];
//       updatedPrices[index] = result.toFixed(0);
//       setChangedPrices(updatedPrices);
//     }
//   };
  const ChangePrice = (selectedOption) => {
    const selectedIndex = options.findIndex(option => option.value === selectedOption.value);
    let result = (options[selectedIndex].value / product.amount) * product.price;
    if (selectedOption.value === 100 || selectedOption.value === 150) {
        result *= 0.85;
    }else if (selectedOption.value === 200) {
        result *= 0.8;
    }
    setChangedPrice(result.toFixed(0));
  };

  const descriptOrdered = (description) => {
    return description.split('/n').map((line, index) => (
      <span key={index}>
        {line[line.length - 2] !== '-' ? <>{line}<br />&nbsp;&nbsp;</> : <>{line}<br/></>}
      </span>
    ));
  };

  return (
    <OneProductDiv>
      <Helmet>
        <meta name="description" content={`${product?.description}`} />
        <meta name="keywords" content={`${product?.title}`} />
      </Helmet>
      <Container>
        <ProductDetails>
          <h1>{product.title}</h1>
          <DescriptionWrapper>
            <p className="information">{descriptOrdered(product.description)}</p>
          </DescriptionWrapper>
          <ControlDiv>
            <span className="amount">
              <Creatable 
                isSearchable={!isMobile} 
                onChange={ChangePrice}
                placeholder={options[0].value}
                options={options}
                styles={customStyles}
                components={{ DropdownIndicator: CustomDropdownIndicator }}
              />
            </span>
            <ButtonsAndPrice>
              <span className="price">Цена: <strong>{changedPrice}</strong></span>
              <button className="buy" onClick={() => addToCart(product, changedPrice)}>Добавить</button>
            </ButtonsAndPrice>
          </ControlDiv>
        </ProductDetails>
        <ProductImage>
          <img src={product.imageURL} alt="Product" />
        </ProductImage>
      </Container>
    </OneProductDiv>
  );
}

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const scaleUp = keyframes`
  from { transform: scale(0.98); }
  to { transform: scale(1); }
`;

const OneProductDiv = styled.div`
  padding-top: 150px;
  animation: ${fadeIn} 1.5s ease-out;
  @media (max-width: 768px) {
    padding-top: 100px;
  }
`;

const Container = styled.div`
  display: flex;
  box-shadow: 0 15px 30px 1px rgba(128, 128, 128, 0.31);
  background: rgba(255, 255, 255, 0.90);
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
  margin: 2.5rem auto;
  height: 450px;
  width: 80%;
  animation: ${scaleUp} 0.5s ease-out;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    height: auto;
  }
`;

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  flex: 40%;
  background-color: #eae3d1;
  position: relative;

  h1 {
    font-family: 'Old Standard TT', serif;
    font-size: 24px;
    color: #344055;
    margin: 0;
    margin-bottom: 12px;
    white-space: wrap;
    
    @media (max-width: 768px) {
      font-size: 14px;
      margin-top: 5px;

    }
  }
`;

const DescriptionWrapper = styled.div`
  max-height: 150px;
  overflow-y: auto;
  margin-bottom: 20px;

  .information {
    // font-family: 'Farsan', cursive;
    font-size: 14px;
    color: #7d7d7d;
    text-align: left; 
    text-indent: 20px;
    @media (max-width: 768px) {
      font-size: 3.3vw; 
    }
  }
`;

const ControlDiv = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const ButtonsAndPrice = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  .price {
    font-size: 18px;
    margin: 10px 0;
    @media (max-width: 768px) {
      font-size: 13px;
    }
  }

  button {
    background: #49C608;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    border: none;
    color: #eee;
    // font-family: 'Farsan', cursive;
    padding: 8px 26px;
    font-size: 1.2rem;
    width: 230px;
    margin: auto;
    transition: all 0.3s ease;
    font-weight: 900;
    &:hover {
      transform: translateY(-4px);
      background-color: darken(#49C608, 10%);
    }
    @media (max-width: 768px) {
      width: 150px;
      font-size: .9rem;
      padding: 6px 16px;
    }
  }
`;

const ProductImage = styled.div`
  position: relative;
  overflow: hidden;
  flex: 60%;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  img {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    transition: transform 0.3s ease-out;
    @media (max-width: 768px) {
      object-fit: contain;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
`;

export default OneProductItem;




