import React from 'react'
import { AboutContainer, Section, Title, Content } from './About'

function AboutBrewing() {
  return (
    <AboutContainer>
        <Section>
      <Title>Как правильно заваривать чай</Title>
      <Content>
      Чай - это не просто напиток, а целый ритуал, который приносит удовольствие и умиротворение. Чтобы получить максимум аромата и вкуса от вашего чая, следуйте этим простым рекомендациям по правильному завариванию:
      <br></br>
      1. Выберите правильную температуру воды: Для различных видов чая подходят разные температуры воды. Например, белый или зеленый чай обычно заваривают при температуре около 75-85 градусов Цельсия, в то время как тёмные чаи лучше заваривать при температуре около 90-95 градусов.<br></br>
      2. Используйте свежую воду: Качество воды влияет на вкус и аромат чая. Используйте свежую, фильтрованную или бутилированную воду для заваривания чая.<br></br>
      3. Выберете правильное количество чая: Определите оптимальное количество чая в зависимости от вида и сорта. Обычно при проведении чайной церемонии заваривается 5 гр. на человека. При заваривании чая в термосе 1 гр. на 100 мл. воды.<br></br>
      4. Время заваривания: Время заваривания также зависит от вида чая. Например, в первые несколько проливов белого и зеленого чая, не рекомендуется держать его долго в заварочном чайнике, готовый напиток может начать горчить. Чай тёмных сортов можно заваривать подольше.<br></br>
      5. Используйте ситечко: Чтобы избежать частиц и мелких фракций чая в напитке, используйте ситечко и чайник с встроенным фильтром.<br></br>
      6. Наслаждайтесь процессом: Заваривание чая - это не только его приготовление, но и целый ритуал. Наслаждайтесь ароматом чая, наблюдайте, как листья раскрывают свой изысканный вкус и аромат!<br></br>
<br></br>
      Следуя этим рекомендациям, вы сможете получить максимальное удовольствие от чаепития и насладиться всеми нюансами вкуса любимого чая.
      </Content>
      </Section>
    </AboutContainer>
  )
}

export default AboutBrewing